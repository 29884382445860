
import md5 from "md5";
import SymmetricKey from "./SymmetricKey";

export function get_key(key){
  if (!key){
    throw new Error();
  }

  return new SymmetricKey({symmetric_key:key, auto_generate:false});
}

export function get_hash(value){
  return md5(`758475943:${value.toLowerCase().trim()}`);
}

export function get_local_key(email){
  return get_key(md5(`758475943:${email.toLowerCase()}`));
}

export function get_user_key(email, password){
  if (!email){
    throw new Error();
  }

  if (!password){
    throw new Error();
  }

  return get_key(md5(`${password}:${email.toLowerCase()}`));
}

export function get_day_string(date){
  return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
}

export function get_day_secret(key, date){
  if (!key){
    throw new Error();
  }

  if (!date){
    throw new Error();
  }

  return md5(`${get_day_string(date)}%${key}`);
}

export function get_day_key(key, date){
  return get_key(get_day_secret(key, date));
}

export function mangle_email(email){
  return md5(email.toLowerCase());
}
